import React from "react";
import { Outlet } from "react-router-dom";
import style from "./index.module.scss";
import { useStoreState } from "easy-peasy";
import Navigation from "../Navigation";
import sponsorBanner from "../../assets/icons/sponsor-banner.png";
import i18next from "i18next";
import teaserBannerEn from "../../assets/banners/en/teaser-banner-en.png"
import teaserBannerEl from "../../assets/banners/el/teaser-banner-el.png"

const Layout = () => {
  const { hideControlBar } = useStoreState((store) => store.navigation);

  const teaserBanner = i18next.language === "en" ? teaserBannerEn : teaserBannerEl
  
  return (
    <>
      <header className={style.banner}>
        <div className={style.appTitleContainer}>
          <img src={teaserBanner} alt="Teaser Banner" className={style.sponsorBanner} />
          {/* <h1 className={style.appTitle}>{t("app.title")}</h1> */}
        </div>
      </header>
      { !hideControlBar && <nav>
        <Navigation />
      </nav> }
      <main className={style.container}>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
