import React, { useEffect, useRef, useState } from "react";

import styles from "./index.module.scss";

import closeIcon from "../../assets/icons/x.svg";

import { useStoreActions, useStoreState } from "easy-peasy";
import PredictionPopUpHeader from "../PredictionPopUpHeader";
import { useTranslation } from "react-i18next";
import DynamicOdds from "../DynamicOdds";
import Loader from "../Loader";
import parse from "html-react-parser";
import Button from "../Button";

const PredictionPopUp = ({
  gameId,
  setOpenPredictionPopUp,
  team_one,
  team_two,
  competitionName,
  date,
  userBets,
  filter,
}) => {
  const { t } = useTranslation();
  const modal = useRef();
  const { lang } = useStoreState((store) => store.user);
  const { chalangeId } = useStoreState((store) => store.game);
  const { isLoading, placePredictionError } = useStoreState((state) => state.matches);
  const { getOdds, postUserBets, setBets, updateUserBets, setPlacePredictionError } = useStoreActions(
    (actions) => actions.matches
  );
  const { newOdds, bets } = useStoreState((store) => store.matches);
  const { getPastMatches, getFutureMatches } = useStoreActions((actions) => actions.matches);
  const [totalPoints, setTotalPoints] = useState(0);

  useEffect(() => {
    const handler = (event) => {
      if (!modal.current) {
        return;
      }

      if (!modal.current.contains(event.target)) {
        setOpenPredictionPopUp(false);
      }
    };

    document.addEventListener("click", handler, true);
    return () => {
      document.removeEventListener("click", handler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (gameId && chalangeId) {
      const fetchData = async () => {
        await getOdds({ gameId, chalangeId, team_one, team_two });
      };
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chalangeId, gameId]);

  const updateMatchesAndClose = async () => {
    switch (filter.value) {
      case "future_games": {
        await getFutureMatches(chalangeId);
        break;
      }
      case "past_games": {
        await getPastMatches(chalangeId);
        break;
      }
      default:
        break;
    }
    setOpenPredictionPopUp(false);
  }

  const sendPrediction = async () => {
    try{
      if (userBets) {
        const data = {
          oddOptionsId: Object.values(bets[gameId]),
          lang: lang
        };
        await updateUserBets({ chalangeId, gameId, data });
        updateMatchesAndClose();
        
      } else {
        const data = {
          challengeId: chalangeId,
          gameId: gameId,
          oddOptionsId: Object.values(bets[gameId]),
          spreadBet: false,
          gameBetTemplateId: newOdds[gameId].betTemplate?._id,
          lang: lang
        };
        await postUserBets(data);
        updateMatchesAndClose();
        
      }
    } catch (error) {
      setPlacePredictionError(error?.response?.data.message);
    }
  };

  useEffect(() => {
    if (userBets?.length) {
      userBets?.forEach((item) => {
        const payload = {
          gameId: item?.gameId,
          oddId: item?.oddId,
          oddOptionId: item?.oddOptionsId,
        };
        setBets(payload);
      });
      setPlacePredictionError(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBets]);

  useEffect(() => {
    if (bets[gameId] && newOdds[gameId]?.odds.length) {
      let sum = 0;
      newOdds[gameId].odds.forEach((odd) => {
        if (odd?.oddType?.externalBettingTypeId === 45) {
          for (let i = 0; i <= 9; i++) {
            for (let j = 0; j <= 9; j++) {
              if (Object.values(bets[gameId]).includes(odd.oddOptions[i][j]?.id)) {
                if (!isNaN(odd.oddOptions[i][j]?.value)) {
                  sum += +odd.oddOptions[i][j]?.value;
                  break;
                }
              }
            }
          }
        } else {
          odd.oddOptions.forEach((oddOption) => {
            if (odd?.oddType?.externalBettingTypeId === 45) {
              for (let i = 0; i <= 9; i++) {
                for (let j = 0; j <= 9; j++) {
                  if (Object.values(bets[gameId]).includes(oddOption[i][j]?.id)) {
                    sum += +oddOption?.value;
                    break;
                  }
                }
              }
            } else if (Object.values(bets[gameId]).includes(oddOption._id)) {
              sum += oddOption?.value;
            }
          });
        }
      });
      setTotalPoints(sum);
      setPlacePredictionError(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bets]);

  return (
    <div className={styles.pop_up_prediction_container}>
      <div className={styles.pop_up_container__inner} ref={modal}>
        <div className={styles.btn_close__container}>
          <button
            className={styles.btn_close}
            onClick={() => {
              setOpenPredictionPopUp(false);
            }}
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <PredictionPopUpHeader
          homeTeam={team_one}
          awayTeam={team_two}
          competitionName={competitionName}
          date={date}
          withBackground={true}
        />
        {newOdds?.[gameId]?.odds.length && !isLoading ? (
          <div className={styles.odds_container}>
            <DynamicOdds
              odds={newOdds?.[gameId]?.odds}
              betTemplate={newOdds?.[gameId]?.betTemplate}
              team_one={team_one}
              team_two={team_two}
              userBets={userBets}
            />
          </div>
        ) : (
          !isLoading && (
            <p className={styles.no_odds}>{parse(t("app.general.no_odds.atm"))}</p>
          )
        )}
        {placePredictionError && <p className={styles.error}>{placePredictionError}</p>}

        {!isLoading &&
        newOdds?.[gameId]?.odds.length &&
        newOdds[gameId]?.betTemplate?.oddTypeSelection?.length ===
          newOdds?.[gameId]?.odds?.length ? (
          <>
            <p className={styles.total_sum}>
              {t("matches.Pts")} <span>{totalPoints.toFixed(2)}</span>
            </p>
            <div className={styles.prediction_button_container}>
            <Button 
                label={userBets ? t("games.button.change_prediction") : t("games.button.place_prediction")}
                size="large"
                variant={userBets ? "secondary" : "primary"}
                onClick={sendPrediction}
            />
            </div>
<div className={styles.total_odds_container}>
  <p className={styles.total_odds_label}>{t("app.general.pts")} </p>
  <p className={styles.total_odds_value}>{totalPoints.toFixed(2)}</p>
</div>
          </>
        ) : null}
        {newOdds[gameId]?.betTemplate?.oddTypeSelection?.length !==
          newOdds?.[gameId]?.odds?.length && newOdds?.[gameId]?.odds.length ? (
          <p className={styles.no_odds}>
            {t("app.general.no_odds.no_prediction_atm")}
            <br />
            {t("app.general.come_back")}
          </p>
        ) : null}
        {isLoading ? (
          <div className={styles.loader}>
            <Loader />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PredictionPopUp;
