import React from "react";
import classNames from "classnames";
import styles from "./index.module.scss";

const Button = ({ label, size = "small", variant = "primary", onClick }) => {
  const buttonClasses = classNames(styles.button, styles[size], styles[variant]);

  return (
    <button className={buttonClasses} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
