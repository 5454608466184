/**
 * Handles the expired JWT token
 * @param {function} api
 * @param {error} error
 * @param {function} getRefreshedToken
 *
 */
const handleJWTExpired = async (api, error, getRefreshedToken) => {
const originalRequests = error.config;
const accessToken = await getRefreshedToken();
if (accessToken) {
    originalRequests._retry = true;

    originalRequests.headers["Authorization"] = `Bearer ${accessToken}`;
    api.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    return api(originalRequests);
}
};

export default handleJWTExpired;