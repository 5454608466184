import moment from "moment";

export const isToday = (date) => {
  const today = new Date();

  if (
    today.getFullYear() === date.getFullYear() &&
    today.getMonth() === date.getMonth() &&
    today.getDate() === date.getDate()
  ) {
    return true;
  }

  return false;
};

export const isOtherDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  if (
    d1.getFullYear() !== d2.getFullYear() ||
    d1.getMonth() !== d2.getMonth() ||
    d1.getDate() !== d2.getDate()
  ) {
    return true;
  }
  return false;
};

export function isWithinTime(date, time) {
  const now = moment();
  const twoDaysAfter = moment().add(10000000, "hours");

  return moment(date).isBetween(now, twoDaysAfter);
}
