import React from "react";
import styles from "./index.module.scss";
import classNames from "classnames";

const Switcher = ({ options, selected, onChange }) => {

  return (
    <div className={styles.switcher}>
      {options.map((item, index) => (
        <button
          key={index}
          className={classNames(styles.button, {
            [styles.active]: item.value === selected.value,
          })}
          onClick={() => onChange(item)}
        >
          {item.label}
        </button>
      ))}
    </div>
  );
};

export default Switcher;
