import React, { useState } from "react";

import styles from "./index.module.scss";

import downIcon from "../../assets/icons/down-icon.svg";

import classNames from "classnames";
import { ReactSVG } from "react-svg";

const Select = ({  options, value, setValue,}) => {
  const [isExtended, setIsExtended] = useState(false);

  return (
    <div
      className={classNames(styles.select_container, "select_container")}
    >
      <div
        className={classNames(styles.selected_value, {
          [styles.active]: value,
        })}
        onClick={() => setIsExtended((prev) => !prev)}
      >
        <div className={styles.text}>
          <p>{value.label}</p>
        </div>

        <div
          className={classNames(styles.icon, {
            [styles.active]: isExtended,
          })}
        >
          <ReactSVG src={downIcon} className={styles.image} />
        </div>
      </div>
      <ul
        className={classNames(styles.options, {
          [styles.active]: isExtended,
        })}
      >
        {
        options?.map((item, index) => {
            if (item.value === value.value) {
              return null; 
            }
            return (
              <li
                key={index}
                onClick={() => {
                  setValue(item);
                  setIsExtended(false);
                }}
                className={classNames(styles.option)}
              >
                {item.label}
              </li>
            );
          })
        }
      </ul>
    </div>
  );
};
export default Select;
