import React, { useEffect, useState } from "react";

import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import { useStoreActions, useStoreState } from "easy-peasy";

const CorrectScore = ({ odd, team_one, team_two, userBets }) => {
  const { t } = useTranslation();
  const { setBets } = useStoreActions((actions) => actions.matches);
  const { bets } = useStoreState((actions) => actions.matches);
  const [teamOneScore, setTeamOneScore] = useState(0);
  const [teamTwoScore, setTeamTwoScore] = useState(0);

  useEffect(() => {
    if (odd?.oddOptions?.[teamOneScore]?.[teamTwoScore]?.value) {
      oddHandler(odd?.oddOptions?.[teamOneScore]?.[teamTwoScore]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamOneScore, teamTwoScore]);

  const oddHandler = (oddOptionId) => {
    const payload = {
      gameId: odd?.gameId,
      oddId: odd?._id,
      oddOptionId,
    };
    setBets(payload);
  };

  useEffect(() => {
    if (userBets) {
      for (let i = 0; i <= 9; i++) {
        for (let j = 0; j <= 9; j++) {
          if (odd.oddOptions[i][j]?.id === bets[odd?.gameId][odd?._id]) {
            setTeamOneScore(i);
            setTeamTwoScore(j);
            break;
          }
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBets]);

  return (
    <div className={styles.correct_score}>
      <h2>{t(`games.bets.${odd?.name}`)}</h2>
      <div className={styles.teams_container}>
        <div className={styles.team_container}>
          <div className={styles.score_container}>
            <button
              onClick={() => setTeamOneScore((prev) => prev - 1)}
              disabled={teamOneScore === 0}
            >
              -
            </button>
            <p className={styles.score}>{teamOneScore}</p>
            <button
              onClick={() => setTeamOneScore((prev) => prev + 1)}
              disabled={teamOneScore === 9}
            >
              +
            </button>
          </div>
        </div>
        <div className={styles.team_container}>
          <div className={styles.score_container}>
            <button
              onClick={() => setTeamTwoScore((prev) => prev - 1)}
              disabled={teamTwoScore === 0}
            >
              -
            </button>
            <p className={styles.score}>{teamTwoScore}</p>
            <button
              onClick={() => setTeamTwoScore((prev) => prev + 1)}
              disabled={teamTwoScore === 9}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <p className={styles.odd}>
        {odd?.oddOptions?.[teamOneScore]?.[teamTwoScore]?.value}{" "}
        {!isNaN(odd?.oddOptions?.[teamOneScore]?.[teamTwoScore]?.value) && t("app.general.pts")}
      </p>
    </div>
  );
};
export default CorrectScore;
