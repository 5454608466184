import React, { useRef, useState } from "react";
import style from "./index.module.scss";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useStoreState } from "easy-peasy";
import { Switcher } from "../../components";

// Function to normalize and convert the text, removing apostrophes and accents
const normalizeAndUppercase = (text) => {
  return text
    .normalize('NFD') // Normalize to decomposed form
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritical marks
    .replace(/['’`´]/g, "") // Remove apostrophes and similar characters
    .toUpperCase(); // Convert to uppercase
};

const PrizesPage = () => {
  const { t } = useTranslation();
  const ref = useRef();
  const { lang } = useStoreState((store) => store.user);

  const prizes = [
    {  
        image: "/images/prizes/Prizes_gold.png",
        title: normalizeAndUppercase(t("prizes_and_rules.prizes.gold.title")),
        description: t("prizes_and_rules.prizes.gold.description")
    },
    {  
      image: "/images/prizes/Prizes_silver.png",
      title: normalizeAndUppercase(t("prizes_and_rules.prizes.silver.title")),
      description: t("prizes_and_rules.prizes.silver.description")
  },
  {  
    image: "/images/prizes/Prizes_bronze.png",
    title: normalizeAndUppercase(t("prizes_and_rules.prizes.bronze.title")),
    description: t("prizes_and_rules.prizes.bronze.description")
},
  ];

  const filters = [
    {
      value: "prizes",
      label: t("prizes_and_rules.select.prizes"),
    },
    {
      value: "rules",
      label: t("prizes_and_rules.select.rules"),
    },
  ];
  const [filter, setFilter] = useState(filters[0]);

  return (
    <div className={classNames(style.prizes_container, "container")} ref={ref}>
      <Switcher options={filters} selected={filter} onChange={setFilter} />
      {filter.value === filters[0].value ? (
        <div className={classNames(style.prizes)}>
          {prizes.map((item, index) => {
            return (
              <div
                key={index}
                className={classNames(style.prize_card)}
              >
                <img src={item?.image} alt={`Prize ${index + 1}`} />
                <div className={style.prize_info}>
                  <h2>{item?.title}</h2>
                  <p>{item?.description}</p>
                </div>
              </div>

            );
          })}
        </div>
      ) : lang === "el" ? ( // TODO: check later for texts
        <div className={style.rules}>
          <span className={style.single}>
            <h3>ΒHΜΑ 1: ΑΝΟIΞΤΕ ΤΗΝ ΕΦΑΡΜΟΓH CLUB 1908</h3>
            <p>
              Κατεβάστε την εφαρμογή CLUB 1908 App στο smartphone ή το tablet σας και ανοίξτε την.  <br />
              Πλοηγηθείτε στην ενότητα «Fan Games» στο μενού και, στη συνέχεια, κάντε κλικ στο «Green Predictor». Η συμμετοχή στο παιχνίδι πρόβλεψης είναι δωρεάν.
            </p>
          </span>
          <span className={style.single}>
            <h3>ΒHΜΑ 2: ΚAΝΤΕ ΤΗΝ ΚΑΘΗΜΕΡΙΝH ΣΑΣ ΠΡOΒΛΕΨΗ</h3>
            <p>
              Επισκεφθείτε καθημερινά την εφαρμογή CLUB 1908 App για να κάνετε ή να ενημερώσετε τις προβλέψεις σας. <br />
              Προβλέψτε τον νικητή και το ακριβές σκορ για κάθε αγώνα. Μπορείτε να κάνετε προβλέψεις μέχρι την έναρξη του αγώνα.
            </p>
          </span>
          <span className={style.single}>
            <h3>ΒHΜΑ 3: ΚΕΡΔIΣΤΕ ΠOΝΤΟΥΣ ΜΕ ΒAΣΗ ΤΙΣ ΠΡΑΓΜΑΤΙΚEΣ ΠΙΘΑΝOΤΗΤΕΣ
            </h3>
            <p>
              Οι πόντοι απονέμονται με βάση τις πραγματικές πιθανότητες <br />
              Οι προβλέψεις με υψηλές πιθανότητες κερδίζουν λιγότερους πόντους, ενώ οι πιο ριψοκίνδυνες προβλέψεις κερδίζουν περισσότερους, διατηρώντας τον ανταγωνισμό συναρπαστικό και δυναμικό.
            </p>
          </span>
          <span className={style.single}>
            <h3>ΒHΜΑ 4: ΚΕΡΔIΣΤΕ ΕΛΚΥΣΤΙΚA ΒΡΑΒΕIΑ</h3>
            <p>
              Τόσο οι νικητές της σεζόν όσο και οι μηνιαίοι νικητές έχουν την ευκαιρία να κερδίσουν ελκυστικά βραβεία. <br />
              Για λεπτομέρειες σχετικά με τα βραβεία, ανατρέξτε στην ενότητα «Βραβεία».
            </p>
          </span>
        </div>
      ) : (
        <div className={style.rules}>
          <span className={style.single}>
            <h3>STEP 1: OPEN THE CLUB 1908 APP</h3>
            <p>
              Download the CLUB 1908 App on your smartphone or tablet and open it. <br />
              Navigate to the “Fan Games” section in the menu and then click on "Green Predictor". Participation in the prediction game is free.
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 2: MAKE YOUR DAILY PREDICTION</h3>
            <p>
              Visit the CLUB 1908 App App daily to make or update your predictions. <br />
              Predict the winner and the exact score for each match. You can make predictions until the match starts.
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 3: EARN POINTS BASED ON REAL PROBABILITIES</h3>
            <p>
              Points are awarded based on real probabilities. <br />
              Predictions with high probability earn fewer points, while riskier predictions earn more, keeping the competition exciting and dynamic.
            </p>
          </span>
          <span className={style.single}>
            <h3>STEP 4: WIN ATTRACTIVE PRIZES</h3>
            <p>
              Both the season winners and the monthly winners have the chance to win attractive prizes.  <br />
              For details about the prizes, check the "Prizes" section.
            </p>
          </span>
        </div>
      )}
    </div>
  );
};

export default PrizesPage;
