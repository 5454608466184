import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';

// App & Routing
import App from './App';
import { BrowserRouter, Routes, Route } from "react-router-dom";

// State Management
import { StoreProvider } from 'easy-peasy';
import store from "./store";

// i18n 
import "./utils/i18n";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreProvider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </StoreProvider>
);
