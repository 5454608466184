/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import { action } from "easy-peasy";
import { thunk } from "easy-peasy";

import api, { ENDPOINTS } from "../../utils/axios";
import urlParamReplacer from "../../utils/replace-param"

const matchesModel = {
  matches: [],
  odds: [],
  isLoading: false,
  isLoadingMatches: false,
  newOdds: [],
  bets: [],
  placePredictionError: null,

  //ACTIONS
  resetMatches: action((state) => {
    state.matches = [];
  }),
  setIsLoading: action((state, payload) => {
    state.isLoading = payload;
  }),
  setIsLoadingMatches: action((state, payload) => {
    state.isLoadingMatches = payload;
  }),
  setBets: action((state, payload) => {
    state.bets[payload.gameId] = {
      ...state.bets[payload.gameId],
      [payload.oddId]: payload.oddOptionId,
    };
  }),
  resetBets: action((state, payload) => { 
    state.bets = payload;
  }),
  setPlacePredictionError: action((state, payload) => {
    state.placePredictionError = payload;
  }),
  populateMatches: action((state, payload) => {
    const date = new Date(payload.scheduledDate);

    let unique = true;
    state.matches.map((element) => {
      if (element.id.toString() === payload._id.toString()) unique = false;
    });

    if (unique)
      state.matches.push({
        id: payload._id,
        status: payload.status,
        odds: null,
        userBets: payload.userBets.length > 0 ? payload.userBets : null,
        team_one: {
          id: payload?.homeTeam?._id,
          name: payload?.homeTeam?.name,
          logo: payload?.homeTeam?.emblemUrl,
          score:
            payload?.homeTeam?.externalTeamId == payload?.stats?.ordinaryTime?.paramParticipantId1
              ? payload?.stats?.ordinaryTime?.paramFloat1
              : payload?.stats?.ordinaryTime?.paramFloat2,
        },
        team_two: {
          id: payload?.awayTeam?._id,
          name: payload?.awayTeam?.name,
          logo: payload?.awayTeam?.emblemUrl,
          score:
            payload?.awayTeam?.externalTeamId == payload?.stats?.ordinaryTime?.paramParticipantId1
              ? payload?.stats?.ordinaryTime?.paramFloat1
              : payload?.stats?.ordinaryTime?.paramFloat2,
        },
        date,
        competitionName: payload.competitionName,
        betTemplate: payload.betTemplate,
      });
  }),
  setOdds: action((state, payload) => {
    const { data, gameId } = payload;

    let correctScoreBets = Array.from(Array(10), () => new Array(9));

    for (let i = 0; i <= 9; i++) {
      for (let j = 0; j <= 9; j++) {
        correctScoreBets[i][j] = {
          value: "No available points",
          id: null,
        };
      }
    }

    data.odds.map((item) => {
      if (item.name == "Correct Score, Ordinary Time") {
        const team = item.oddOptions[0]?.name.split(" ").slice(0, -1).join(" ");
        item.oddOptions.map((el) => {
          let data = {
            team: el.name.split(" ").slice(0, -1).join(" "),
            score: {
              team_one: Number(el.name.split(" ")[el.name.split(" ").length - 1].split("-")[0]),
              team_two: Number(el.name.split(" ")[el.name.split(" ").length - 1].split("-")[1]),
            },
            value: el.value.toFixed(2),
          };

          if (data.score.team_one <= 9 && data.score.team_two <= 9) {
            if (data.team == team) {
              correctScoreBets[data.score.team_one][data.score.team_two].value = data.value;
              correctScoreBets[data.score.team_one][data.score.team_two].id = el._id;
            } else {
              correctScoreBets[data.score.team_two][data.score.team_one].value = data.value;
              correctScoreBets[data.score.team_two][data.score.team_one].id = el._id;
            }
          }
        });
      }
    });

    state.newOdds[gameId] = {
      betTemplate: data.betTemplate,
      odds: data?.odds.map((item) => {
        return {
          ...item,
          oddOptions:
            item?.oddType?.externalBettingTypeId === 45 ? correctScoreBets : item?.oddOptions,
        };
      }),
    };
  }),
  //THUNCKS
  getPastMatches: thunk(async (actions, id) => {
    actions.setIsLoadingMatches(true);
    actions.resetMatches();
    

    try {
      const { data } = await api.get(urlParamReplacer(ENDPOINTS.GAMES.PAST_MATCHES, id));
      data.map((match) => {
        actions.populateMatches(match);
      });
      actions.setIsLoadingMatches(false);
    } catch (error) {
      console.error(error);
      actions.setIsLoadingMatches(false);
    }
  }),
  getFutureMatches: thunk(async (actions, id) => {
    actions.setIsLoadingMatches(true);
    actions.resetMatches();
    try {
      const { data } = await api.get(urlParamReplacer(ENDPOINTS.GAMES.FUTURE_MATCHES, id));

      data?.map((match) => {
        actions.populateMatches(match);
      });
    } catch (error) {
      console.error(error);
    }
    actions.setIsLoadingMatches(false);
  }),
  getOdds: thunk(async (actions, payload) => {
    actions.setIsLoading(true);
    const { gameId, chalangeId, team_one, team_two } = payload;
    const response = await api.get(urlParamReplacer(ENDPOINTS.ODDS.ODDS, gameId, chalangeId));
    const data = await response.data;
    actions.setOdds({ data, team_one, team_two, gameId });
    actions.setIsLoading(false);
  }),
  postUserBets: thunk(async (actions, payload) => {
    await api.post(ENDPOINTS.ODDS.USER_BETS, payload);
    actions.setPlacePredictionError(null);
  }),
  updateUserBets: thunk(async (actions, payload) => {
    const { chalangeId, gameId, data } = payload;
    await api.patch(urlParamReplacer(ENDPOINTS.ODDS.USER_BETS_UPDATE, chalangeId, gameId), data);
    actions.setPlacePredictionError(null);
  }),
};
export default matchesModel;
