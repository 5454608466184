import React from "react";

import style from "./index.module.scss";
import Loader from "../../components/Loader";

const ErrorPage = () => {

  return (
    <div className={style.error}>
      <Loader />
    </div>
  );
};

export default ErrorPage;
